import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { request } from '../../lib/apiRequestWrapper';
import _filter from 'lodash/filter';
import Modal from '../../audi-ui-components/Modal';
import LoadingOverlay from '../../components/LoadingOverlay';
import VehicleHero from '../../components/VehicleHero';
import Warranty from './components/Warranty';
import RecommendedProducts from './components/RecommendedProducts';
import ServiceHistory from './components/ServiceHistory';
import ServicePlan from './components/ServicePlan';
import RoadsideAssist from './components/RoadsideAssist';
import SoldVehicle from './components/SoldVehicle';
import TradeVehicle from './components/TradeVehicle';
import PreferredServiceDealerForm from '../Profile/forms/PreferredServiceDealerForm';
import TradeInForm from './forms/TradeInForm';
import BuyServicePlan from './forms/BuyServicePlan';
import ExtWarrantyForm from './forms/ExtWarrantyForm';
import ChangeOwnerForm from './forms/ChangeOwnerForm';
import UploadImageForm from './forms/UploadImageForm';

import { connect } from 'react-redux';
import { fetchDealers } from '../../redux/Dealers';
import { updateProfile } from '../../redux/Profile';
import { getShopItems, setVehicle } from '../../redux/Vehicles';
const mapStateToProps = state => {
    return {
        vehicleByVin: state.vehicles.vehicleByVin,
        merchandiseByVin: state.vehicles.merchandiseByVin,
        isLoading: state.vehicles.isLoading || state.profile.isLoading || state.dealers.isLoading,
        profile: state.profile.data ? state.profile.data : {},
        dealers: state.dealers.list,
        preferredServiceLocation: state.profile.preferredServiceLocation
    };
};
const mapDispatchToProps = dispatch => {
    return {
        fetchDealers: () => { dispatch(fetchDealers()); },
        getShopItems: (vin) => { dispatch(getShopItems(vin)); },
        setVehicle: (v) => { dispatch(setVehicle(v)); },
        updateProfile: (data, cb, errorCb) => { dispatch(updateProfile(data, cb, errorCb)); }
    }
}

class Vehicle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeModal: (props.location && props.location.state && props.location.state.showModal) ? props.location.state.showModal : null,
            serviceHistory: [],
            isEditingImage: false,
            editPreviewUrl: null
        }
    }

    componentDidMount() {
        const {dealers, merchandiseByVin, vin, getShopItems, fetchDealers} = this.props;
        console.log("Vehicle componentDidMount");
        window.scrollTo(0, 0);
        if (!dealers || dealers.length === 0) {
            fetchDealers();
        }
        if (!merchandiseByVin[vin]) {
            getShopItems(vin);
        }
        request(
            `${process.env.RAZZLE_API}/1/account/vehicles/${vin}/services`
        ).then((data) => {
            this.setState({serviceHistory: data});
        }).catch((error) => {
            console.error(error);
        });
    }

    setActiveModal = (m) => {
        this.setState({activeModal: m});
    }
    closeModal = () => {
        this.setState({activeModal: null});
    }

    setEditPreviewUrl = (url) => {
        this.setState({editPreviewUrl: url});
    }

    updateVehicleImage = (i) => {
        const {vin, vehicleByVin} = this.props;
        let vehicle = vehicleByVin[vin];
        vehicle.imageUrl = i;
        this.props.setVehicle(vehicle);
    }

    render() {
        if (this.props.isLoading) {
            return (
                <LoadingOverlay type="placeholder" />
            );
        }
        const {vin, merchandiseByVin, profile, updateProfile, dealers} = this.props;
        const {activeModal, serviceHistory, isEditingImage, editPreviewUrl} = this.state;
        const vehicle = this.props.vehicleByVin && this.props.vehicleByVin[vin];
        const hasUserImage = vehicle && vehicle.imageUrl;
        const vehicleSettings = profile.settings && profile.settings[vin] ? profile.settings[vin] : {};
        let roadside = null;
        if (vehicle && vehicle.policies && vehicle.policies.length > 0) {
            roadside = _filter(vehicle.policies, (o) => {
                let a = Boolean(o.policyName && o.policyStartDate && o.policyEndDate);
                let b = false;
                if (a) {
                    if (o.policyName.toLowerCase().includes("roadside")) { b = true; }
                    if (o.policyName.toLowerCase().includes("audi sira")) { b = true; }
                }
                return a && b;
            });
        }
        return (
            <div className="page-wrapper vehicle">
                {vehicle && (
                    <div className="content-wrapper">
                        <VehicleHero vin={vehicle.vin}
                            modelName={vehicle.modelNameSD}
                            modelYear={vehicle.modelYear}
                            regoNo={vehicle.regoNo}
                            detail
                            imageUrl={(editPreviewUrl && isEditingImage) ? editPreviewUrl : hasUserImage ? vehicle.imageUrl : null}
                            onClickEdit={() => { this.setState({isEditingImage: true}); }}
                        />

                        {isEditingImage &&
                            <UploadImageForm
                                vin={vin}
                                modelNameSD={vehicle && vehicle.modelNameSD}
                                updateVehicleImage={this.updateVehicleImage}
                                setEditPreviewUrl={this.setEditPreviewUrl}
                                onClose={() => { this.setState({isEditingImage: false, editPreviewUrl: null}); }}
                                hasUserImage={hasUserImage}
                            />
                        }

                        <div className="vehicle-detail">
                            <div className="px-3 px-small-0">

                                <Warranty {...vehicle} showActiveModal={this.setActiveModal} />

                                <RecommendedProducts products={merchandiseByVin[vin]} />

                                <div className="row">
                                    <div className="col-12 col-medium-4 pb-3">
                                        <RoadsideAssist plans={roadside} />
                                    </div>

                                    <div className="col-12 col-medium-4 pb-3">
                                        <ServicePlan
                                            servicePlan={vehicle.servicePlan}
                                            modelName={vehicle.modelNameSD}
                                            warrantyStartDate={vehicle.warrantyStartDate}
                                            setActiveModal={this.setActiveModal}
                                            activeModal={activeModal}
                                        />
                                    </div>

                                    <div className="col-12 col-medium-4 pb-3">
                                        <ServiceHistory
                                            serviceHistory={serviceHistory}
                                            preferredServiceLocation={this.props.preferredServiceLocation}
                                            setActiveModal={this.setActiveModal}
                                            activeModal={activeModal}
                                            rego={vehicle.regoNo}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-xsmall-6 pb-3">
                                        <SoldVehicle showActiveModal={this.setActiveModal} />
                                    </div>
                                    {!vehicleSettings.hideTradeIn && <div className="col-12 col-xsmall-6 pb-3">
                                        <TradeVehicle onClick={() => { this.setActiveModal('enquire-trade'); }} onHide={() => { updateProfile({ settings: { [vin]: { hideTradeIn: true } } }); }} />
                                    </div>}
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                <Modal isActive={activeModal === 'enquire-warranty'}
                    closeButton
                    close={this.closeModal}
                    modalStyle="layer">
                    <ExtWarrantyForm profile={profile} vin={vin} modelNameSD={vehicle && vehicle.modelNameSD} dealers={_filter(dealers, { hasSales: true })} />
                </Modal>

                <Modal isActive={activeModal === 'change-ownership'}
                    closeButton
                    close={this.closeModal}
                    modalStyle="layer">
                    <ChangeOwnerForm profile={profile} vin={vin} regoNo={vehicle && vehicle.regoNo} />
                </Modal>

                <Modal isActive={activeModal === 'enquire-trade'}
                    closeButton
                    close={this.closeModal}
                    modalStyle="layer">
                    {activeModal === 'enquire-trade' && <TradeInForm profile={profile} vin={vin} modelNameSD={vehicle && vehicle.modelNameSD} dealers={_filter(dealers, { hasSales: true })} />}
                </Modal>

                <Modal isActive={activeModal === 'buy-service-plan'}
                    closeButton
                    close={this.closeModal}
                    modalStyle="layer">
                    {activeModal === 'buy-service-plan' && <BuyServicePlan profile={profile} vehicle={vehicle && vehicle} preferredServiceLocation={this.props.preferredServiceLocation} />}
                </Modal>

                <Modal isActive={activeModal === 'change-dealer'} closeButton close={this.closeModal} modalStyle="layer">
                    {activeModal === 'change-dealer' && <PreferredServiceDealerForm alwaysShowForm onCancel={this.closeModal} gtmEventLabel="vehicle" />}
                </Modal>

                <Helmet>
                    <title>Vehicle Listing | myAudi Australia</title>
                </Helmet>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
