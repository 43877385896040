import { Link } from 'react-router-dom';
import { PATH_BENEFITS, PATH_EXPERIENCE_OVERVIEW } from "../../constants";

const MainNavList = () => {
  return (
    <ul>
      <li>
        <Link to={PATH_BENEFITS}>Ownership Benefits</Link>
      </li>
      <li>
        <Link to={PATH_EXPERIENCE_OVERVIEW}>Audi Experience</Link>
      </li>
      <li>
        <a href="https://www.audi.com.au/en/audi-new-car-search/" target="_blank" rel="noopener noreferrer">Find a new Audi</a>
      </li>
    </ul>
  );
};

export default MainNavList;
