import React from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from './SocialIcons';
import { connect } from 'react-redux';
import { PATH_PROFILE } from '../constants';
import { withRouter } from 'react-router';

const Footer = ({ isAuthed, isLoadingProfile, hasMFAEnabled, location }) => {
  return (
    <>
    {isAuthed && !isLoadingProfile && !hasMFAEnabled && location.pathname !== PATH_PROFILE &&
      <footer className="footer mfa-note outer-wrapper" style={{position:"sticky", bottom:"0", zIndex:"19"}}>
        <div className="py-3 content-wrapper aui-color-red aui-color-text-light">
          We strongly recommend enabling 2FA to secure your account. <Link to={PATH_PROFILE} className="aui-textlink">Go to your Profile page</Link>
        </div>
      </footer>
    }
    <footer className="footer outer-wrapper">
      <div className="content-wrapper aui-color-black aui-color-text-light py-7">
        <div className="row align-items-center">
          
          <div className="col-12 col-medium-auto py-3">
            <span className="pe-medium-3 pe-large-7">&copy; Copyright Audi Australia. All rights reserved.</span>
          </div>

          <div className="col-auto py-3">
            <span className="pe-3"><Link to="/contact-us">Contact Us</Link></span>
          </div>
          <div className="col-auto py-3">
            <span className="pe-3"><Link to="/faq">FAQ</Link></span>
          </div>
          <div className="col-auto py-3">
            <span className="pe-3"><a href="https://www.audi.com.au/en/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>
          </div>
          <div className="col-auto py-3">
            <a href="https://www.audi.com.au/en/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </div>

          <div className="col-12 col-medium py-3 social text-medium-end order-first order-medium-last">
            <SocialIcons />
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isAuthed: state.profile.isAuthed,
    hasMFAEnabled: Boolean(
      state.profile.isAuthed && state.profile.data && state.profile.data.hasMFAEnabled
    ),
    isLoadingProfile: state.profile.isLoading,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
