import React from 'react';
import Button from '../../../audi-ui-components/Button';
import IconCar from '../../../audi-ui-components/icons/Car';
import moment from 'moment';
import _find from 'lodash/find';
import { gtmPush } from '../../../lib/gtm';

const RoadsideAssist = ({plans}) => {

    const today = moment();

    let l = plans ? plans.length : 0;
    let hasPlan = l > 0;
    let _plans = [];
    let plan = null;
    for (let i=0; i<l; i++) {
        let p = {...plans[i]};
        p.planStarted = today.isAfter(plans[i].policyStartDate);
        p.planEnded = today.isAfter(plans[i].policyEndDate);
        if (p.planStarted && !p.planEnded) {
            plan = p;
        }
        _plans.push(p);
    }
    if (plan === null && hasPlan) {
        // no current plan found
        if (l = 1) {
            // there is only 1 plan
            plan = _plans[0];
        } else {
            // check for plan that hasn't started yet
            plan = _find(_plans, {planStarted: false});
            if (!plan) {
                // just show the last one in the list
                plan = _plans[l-1];
            }
        }
    }

    return (
        <div className="aui-color-gray95 pl-medium-3 d-flex flex-column" style={{ height: '100%' }}>

            <div className="row align-items-center pt-7">
                <div className="col-12 col-small-2 d-flex justify-content-center justify-content-small-end px-2 py-4">
                    <IconCar />
                </div>
                <div className="col-10 offset-1 col-small-8 offset-small-0">
                    <h2 className="aui-headline-3">
                        <b>{plan && plan.policyName ? plan.policyName : "Roadside Assistance"}</b>
                    </h2>
                </div>
            </div>

            <div className="row pt-3 pb-7">
                <div className="col-1 col-small-2"></div>
                <div className="col-10 col-small-8">

                    {hasPlan && !plan.planEnded && <>
                        <p className="mb-5">You can drive with the security that one phone call delivers help at any time. 24 hours a day, 7 days a week.</p>
                        {!plan.planStarted && <>
                            <p><b>Roadside Start Date</b></p>
                            <p className="mb-5 aui-headline-5"><b>{moment(plan.policyStartDate).format('MMM Do YYYY')}</b></p>
                        </>}
                        <p><b>Current Roadside Expiry Date</b></p>
                        <p className="mb-5 aui-headline-5"><b>{moment(plan.policyEndDate).format('MMM Do YYYY')}</b></p>
                        <p className="mb-5">Call <a href="tel:1800818833">1800 818 833</a> for assistance.</p>
                        <p>
                            <Button
                                label="Click here to find out more"
                                buttonType="primary"
                                href="https://www.audi.com.au/au/web/en/owners/service/roadside-assistance.html"
                                target="_blank" rel="noopener noreferrer"
                                onClick={() => { gtmPush("myAudi", "roadsideAssistance", "vehicle"); }}
                            />
                        </p>
                    </>}

                    {(!hasPlan || plan.planEnded) && <>
                        <p className="mb-3">This car no longer has Audicare Roadside Assist.</p>
                        <p className="mb-5">If you Service your vehicle at an authorised Audi dealership you will receive 12<sup>&#94;</sup> months Roadside Assistance at no extra cost<sup>*</sup>.</p>
                        <p className="aui-small">&#94; 24 months Audi Service Initiated Roadside Assistance membership for Audi e-tron models.</p>
                        <p className="aui-small">* Available on eligible Audi vehicles that are at least 3 years old or 6 years old for e-tron models (from the original new car warranty start date) that have a scheduled service performed at an authorised Audi dealer and that are not the subject of, or have the benefit of, any other Audi roadside assistance membership.</p>
                    </>}

                </div>
            </div>

        </div>
    );
}

export default RoadsideAssist;
